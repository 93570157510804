import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle } from 'recompose';
import connect from 'react/hoc/connectProxy';
import Masonry from 'react-masonry-css';

import { subsetsShape } from 'shapes/subsets';
import { BREAKPOINTS_MAP } from 'config/style';
import { getCommunities } from 'redux/communities/actions';

import withAllSubsets from 'react/business/subsets/card/connect/withAllSubsets.connect';
import SubsetsCard from 'react/business/subsets/card/item/SubsetsCard';

import classNames from './subset-cardlist.module.scss';

const breakpointsCol = {
  default: 3,
  [BREAKPOINTS_MAP.tiny]: 1,
  [BREAKPOINTS_MAP.small]: 1,
  [BREAKPOINTS_MAP.medium]: 2,
  [BREAKPOINTS_MAP.large]: 2,
  [BREAKPOINTS_MAP['extra-large']]: 4,
};

const enhancer = compose(
  withAllSubsets,

  connect(
    null,
    { getCommunities },
  ),

  lifecycle({
    /**
     * Load communities list on mount.
     */
    async componentDidMount() {
      await this.props.getCommunities({
        populateDomains: false,
        countProducts: false,
      });
    },
  }),

  memo,
);

const SubsetsCardList = ({ subsets }) => {
  const cards = [];
  Object.entries(subsets).forEach(([key, value]) => {
    cards.push(<SubsetsCard communityId={key} key={key} items={value} />);
  });

  return (
    <Masonry
      breakpointCols={breakpointsCol}
      className={classNames.grid}
      columnClassName={classNames.column}
    >
      {cards}
    </Masonry>
  );
};
SubsetsCardList.displayName = 'SubsetsCardList';

SubsetsCardList.propTypes = {
  subsets: PropTypes.objectOf(PropTypes.arrayOf(subsetsShape)),
};

SubsetsCardList.defaultProps = {
  subsets: {},
};

export default enhancer(SubsetsCardList);
