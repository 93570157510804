import React, { memo } from 'react';
import { compose } from 'recompose';

import SubsetsCardList from 'react/business/subsets/card/list/SubsetsCardList';

const enhancer = compose(
  memo,
);

const SubsetsPage = () => (
  <div>
    <SubsetsCardList />
  </div>
);

export default enhancer(SubsetsPage);
