import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { noop } from 'lodash';
import cn from 'classnames';
import { Icon, Tooltip } from 'antd';
import { formatRoute } from 'react-router-named-routes';
import { withRouter } from 'react-router-dom';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl,
  intlShape,
} from 'react-intl';

import connect from 'react/hoc/connectProxy';
import withExpandState from 'react/hoc/withExpandState';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import Button from 'react/generic/button/Button';
import ROUTES from 'react/routes';

import { subsetsShape } from 'shapes/subsets';
import { communityShape } from 'shapes/community';

import { selectCommunityById } from 'redux/communities/selectors';

import globalMessages from 'config/global.messages';
import businessMessages from 'config/business.messages';
import messages from './subset-card.messages';

import classNames from './subset-card.module.scss';

const withEventHandler = withHandlers({
  onClickSeeSubsetProducts: ({ items, history }) => () => {
    const query = items.reduce((acc, current) => `${acc}subsetIds=${current.id}&`, '');
    history.push(`${formatRoute(ROUTES.PRODUCTS)}?${query}`);
  },

  onClickSeeSubsetProduct: ({ history }) => (item) => {
    const query = `subsetIds=${item.id}`;
    history.push(`${formatRoute(ROUTES.PRODUCTS)}?${query}`);
  },
});

const enhancer = compose(
  withRouter,
  withExpandState,
  withEventHandler,

  // Load community object
  connect(
    (state, props) => ({
      community: selectCommunityById(state, props.communityId),
    }),
  ),

  injectIntl,
  memo,
);

const SubsetsCard = ({
  community,
  items,
  isPanelExpanded,
  onTogglePanel,
  intl,
  onClickSeeSubsetProducts,
  onClickSeeSubsetProduct,
}) => {
  const totalProductCount = items.reduce((acc, current) => acc + current.productCount, 0);

  return (
    <div className={classNames.card}>
      <div className={classNames.top}>
        <div className={classNames.title}>
          <button
            type="button"
            className={cn('unstyled with-pointer', classNames.titleButton)}
          >
            {community?.name}
          </button>
        </div>
      </div>

      <div className={classNames.divider} />

      <div className={classNames.bottom}>
        <div>
          <div className={classNames.totalProductCount}>
            <FormattedHTMLMessage
              {...messages.TOTAL_PRODUCT_COUNT}
              values={{ count: totalProductCount }}
            />
          </div>
          <div className={classNames.abbsCount}>
            <FormattedMessage
              {...messages.SUBSETS_COUNT}
              values={{ count: items?.length || 0 }}
            />
          </div>
        </div>
        <div className={classNames.arrow}>
          <button
            type="button"
            className={cn('unstyled with-pointer')}
            onClick={onTogglePanel}
            title={(isPanelExpanded ?
              intl.formatMessage({ ...globalMessages.HIDE_DETAILS })
              : intl.formatMessage({ ...globalMessages.SHOW_DETAILS }))}
          >
            <Icon type={isPanelExpanded ? 'up-circle' : 'down-circle'} />
          </button>
        </div>
      </div>

      {(isPanelExpanded && items?.length > 0)
        && (
          <>
            <ul className={classNames.abbList}>
              { items.map(subset => (
                <li key={subset.id} className={classNames.abbItem}>
                  <button
                    type="button"
                    className={cn(classNames.title, 'unstyled with-pointer')}
                    onClick={() => onClickSeeSubsetProduct(subset)}
                  >
                    {subset.name}
                    {subset.description && (
                    <Tooltip placement="top" title={subset.description}>
                      <span className={classNames.infoTooltip}>
                        <Asset name={ICON_MAP.info} />
                      </span>
                    </Tooltip>
                    )}
                  </button>
                  <button
                    type="button"
                    className="unstyled with-pointer"
                    onClick={() => onClickSeeSubsetProduct(subset)}
                  >
                    <span className={classNames.count}>
                      <FormattedMessage
                        {...businessMessages.PRODUCTS_COUNT}
                        values={{ count: subset.productCount || 0 }}
                      />
                    </span>
                  </button>
                </li>
              ))}
            </ul>

            <div className={classNames.buttonContainer}>
              <Button
                outline
                overrideClassName={classNames.abbProductsButton}
                overrideClassNames={classNames}
                onClick={onClickSeeSubsetProducts}
              >
                <FormattedMessage {...messages.SEE_SUBSET_PRODUCTS} />
              </Button>
            </div>
          </>
        )}
    </div>
  );
};

SubsetsCard.displayName = 'SubsetsCard';

SubsetsCard.propTypes = {
  community: communityShape.isRequired,
  items: PropTypes.arrayOf(subsetsShape),
  isPanelExpanded: PropTypes.bool,
  onTogglePanel: PropTypes.func,
  intl: intlShape.isRequired,
  onClickSeeSubsetProducts: PropTypes.func,
  onClickSeeSubsetProduct: PropTypes.func,
};

SubsetsCard.defaultProps = {
  items: [],
  isPanelExpanded: false,
  onTogglePanel: noop,
  onClickSeeSubsetProducts: noop,
  onClickSeeSubsetProduct: noop,
};

export default enhancer(SubsetsCard);
